import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  Discount_Type_Enum_Enum,
  GetDiscountTrainingDocument,
  GetDiscountTrainingQuery,
  GetDiscountTrainingQueryVariables,
  Order_By,
  useGetDiscountTrainingsQuery,
  useGetTrainingsQuery,
  useUpsertDiscountTrainingMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'

export type DiscountTrainingModalType = {
  show: (args: { discountId: number; trainingId?: number }) => void
}

type FormDiscountTraining = {
  trainingId: number
  maxCount: number
}

const DiscountTrainingModal = forwardRef<
  DiscountTrainingModalType,
  {
    onChange: () => void
    discountType: Discount_Type_Enum_Enum
  }
>(({ onChange, discountType }, ref) => {
  const [show, setShow] = useState(false)
  const [discountId, setDiscountId] = useState<number>()
  const [trainingId, setTrainingId] = useState<number>()
  const client = useClient()
  const [{ fetching: upsertDiscountTrainingFetching }, upsertDiscountTraining] =
    useUpsertDiscountTrainingMutation()
  const [{ data: trainingsData, fetching: trainingsFetching }] =
    useGetTrainingsQuery({
      variables: { limit: 0xfffff, orderBy: [{ name: Order_By.Asc }] },
    })
  const [{ data: discountTrainingsData }] = useGetDiscountTrainingsQuery({
    variables: { discountId: discountId || 0, limit: 0xfffff },
  })
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormDiscountTraining>({
    defaultValues: {
      trainingId: undefined,
      maxCount: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ discountId, trainingId }) => {
      setDiscountId(discountId)
      setTrainingId(trainingId)
      reset({
        trainingId,
        maxCount: undefined,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (discountId && trainingId) {
      client
        .query<GetDiscountTrainingQuery, GetDiscountTrainingQueryVariables>(
          GetDiscountTrainingDocument,
          {
            discountId,
            trainingId,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.discount_training_by_pk) {
            reset({
              trainingId: data.discount_training_by_pk.trainingId,
              maxCount:
                typeof data.discount_training_by_pk.maxCount === 'number'
                  ? data.discount_training_by_pk.maxCount
                  : undefined,
            })
          }
        })
    }
  }, [client, discountId, reset, trainingId])

  async function doSaveDiscountTraining(
    DiscountTraining: FormDiscountTraining
  ) {
    setGeneralError(undefined)

    if (!discountId) {
      setGeneralError('Missing discountId')
      return
    }

    const { error } = await upsertDiscountTraining({
      ...DiscountTraining,
      discountId,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setDiscountId(undefined)
    setTrainingId(undefined)
    onChange()
  }

  const discountTrainings = useMemo(
    () => discountTrainingsData?.discount_training || [],
    [discountTrainingsData?.discount_training]
  )

  const availableTrainings = useMemo(() => {
    return (trainingsData?.training || []).filter(
      ({ id }) =>
        id === watch('trainingId') ||
        !discountTrainings.find(
          (discountTraining) => discountTraining.trainingId === id
        )
    )
  }, [discountTrainings, trainingsData, watch])

  return (
    <Modal
      show={show}
      onHide={() => {
        setDiscountId(undefined)
        setTrainingId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveDiscountTraining)}>
        <Modal.Header closeButton>
          <Modal.Title>Rabat - Szkolenie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="trainingId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Szkolenie" className="mb-3">
                <Form.Select
                  disabled={!availableTrainings.length || trainingsFetching}
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać szkolenie</option>
                  {availableTrainings.map((training) => (
                    <option value={training.id} key={training.id}>
                      {training.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.trainingId && (
                  <Form.Control.Feedback>
                    {errors.trainingId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          {discountType === Discount_Type_Enum_Enum.WholeOrderWithItems && (
            <FloatingLabel label="Maksymalna liczba wystąpień" className="mb-3">
              <Form.Control
                isInvalid={!!errors.maxCount}
                type="text"
                placeholder="Cena"
                {...register('maxCount', {
                  valueAsNumber: true,
                })}
              />
              {errors.maxCount && (
                <Form.Control.Feedback>
                  {errors.maxCount.message}
                </Form.Control.Feedback>
              )}{' '}
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={upsertDiscountTrainingFetching}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default DiscountTrainingModal
