import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { Alert, Button, ButtonGroup, Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router'
import {
  Discount_Type_Enum_Enum,
  GetDiscountDocument,
  GetDiscountQuery,
  GetDiscountQueryVariables,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import DiscountSettings from './discount/DiscountSettings'
import DiscountTests from './discount/DiscountTests'
import DiscountConsultations from './discount/DiscountConsultations'
import DiscountTrainings from './discount/DiscountTrainings'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

const Discount: FC = () => {
  const pageTitle = 'Ustawienia'
  const client = useClient()
  const { id } = useParams()
  const navigate = useNavigate()
  const [deletedAt, setDeletedAt] = useState<string | null>()
  const [discountType, setDiscountType] = useState<Discount_Type_Enum_Enum>()

  useEffect(() => {
    if (id) {
      client
        .query<GetDiscountQuery, GetDiscountQueryVariables>(
          GetDiscountDocument,
          {
            id: parseInt(id),
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.discount_by_pk) {
            setDeletedAt(data.discount_by_pk.deletedAt)
          }
        })
    }
  }, [id, client])

  if (!id) {
    return <DiscountSettings />
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/discounts')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      {deletedAt && <Alert variant="danger">Rabat usunięty {deletedAt}</Alert>}
      <Tabs defaultActiveKey="settings" className="mb-3 mt-3">
        <Tab eventKey="settings" title="Ustawienia">
          <DiscountSettings
            id={parseInt(id)}
            readOnly={!!deletedAt}
            onDiscountTypeChange={setDiscountType}
          />
        </Tab>
        {discountType &&
          [
            Discount_Type_Enum_Enum.SomeItems,
            Discount_Type_Enum_Enum.WholeOrderWithItems,
          ].includes(discountType) && [
            <Tab eventKey="discountTests" title="Badania">
              <DiscountTests
                id={parseInt(id)}
                readOnly={!!deletedAt}
                discountType={discountType}
              />
            </Tab>,
            <Tab eventKey="discountConsultations" title="Konsultacje">
              <DiscountConsultations
                id={parseInt(id)}
                readOnly={!!deletedAt}
                discountType={discountType}
              />
            </Tab>,
            <Tab eventKey="discountTrainings" title="Szkolenia">
              <DiscountTrainings
                id={parseInt(id)}
                readOnly={!!deletedAt}
                discountType={discountType}
              />
            </Tab>,
          ]}
      </Tabs>
    </div>
  )
}

export default Discount
