import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  Discount_Type_Enum_Enum,
  GetDiscountConsultationDocument,
  GetDiscountConsultationQuery,
  GetDiscountConsultationQueryVariables,
  Order_By,
  useGetDiscountConsultationsQuery,
  useGetConsultationsQuery,
  useUpsertDiscountConsultationMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'

export type DiscountConsultationModalType = {
  show: (args: { discountId: number; consultationId?: number }) => void
}

type FormDiscountConsultation = {
  consultationId: number
  maxCount: number
}

const DiscountConsultationModal = forwardRef<
  DiscountConsultationModalType,
  {
    onChange: () => void
    discountType: Discount_Type_Enum_Enum
  }
>(({ onChange, discountType }, ref) => {
  const [show, setShow] = useState(false)
  const [discountId, setDiscountId] = useState<number>()
  const [consultationId, setConsultationId] = useState<number>()
  const client = useClient()
  const [
    { fetching: upsertDiscountConsultationFetching },
    upsertDiscountConsultation,
  ] = useUpsertDiscountConsultationMutation()
  const [{ data: consultationsData, fetching: consultationsFetching }] =
    useGetConsultationsQuery({
      variables: { limit: 0xfffff, orderBy: [{ name: Order_By.Asc }] },
    })
  const [{ data: discountConsultationsData }] =
    useGetDiscountConsultationsQuery({
      variables: { discountId: discountId || 0, limit: 0xfffff },
    })
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormDiscountConsultation>({
    defaultValues: {
      consultationId: undefined,
      maxCount: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ discountId, consultationId }) => {
      setDiscountId(discountId)
      setConsultationId(consultationId)
      reset({
        consultationId,
        maxCount: undefined,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (discountId && consultationId) {
      client
        .query<
          GetDiscountConsultationQuery,
          GetDiscountConsultationQueryVariables
        >(GetDiscountConsultationDocument, {
          discountId,
          consultationId,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.discount_consultation_by_pk) {
            reset({
              consultationId: data.discount_consultation_by_pk.consultationId,
              maxCount:
                typeof data.discount_consultation_by_pk.maxCount === 'number'
                  ? data.discount_consultation_by_pk.maxCount
                  : undefined,
            })
          }
        })
    }
  }, [client, discountId, reset, consultationId])

  async function doSaveDiscountConsultation(
    DiscountConsultation: FormDiscountConsultation
  ) {
    setGeneralError(undefined)

    if (!discountId) {
      setGeneralError('Missing discountId')
      return
    }

    const { error } = await upsertDiscountConsultation({
      ...DiscountConsultation,
      discountId,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setDiscountId(undefined)
    setConsultationId(undefined)
    onChange()
  }

  const discountConsultations = useMemo(
    () => discountConsultationsData?.discount_consultation || [],
    [discountConsultationsData?.discount_consultation]
  )

  const availableConsultations = useMemo(() => {
    return (consultationsData?.consultation || []).filter(
      ({ id }) =>
        id === watch('consultationId') ||
        !discountConsultations.find(
          (discountConsultation) => discountConsultation.consultationId === id
        )
    )
  }, [discountConsultations, consultationsData, watch])

  return (
    <Modal
      show={show}
      onHide={() => {
        setDiscountId(undefined)
        setConsultationId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveDiscountConsultation)}>
        <Modal.Header closeButton>
          <Modal.Title>Rabat - Konsultacja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="consultationId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Konsultacja" className="mb-3">
                <Form.Select
                  disabled={
                    !availableConsultations.length || consultationsFetching
                  }
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać konsultację</option>
                  {availableConsultations.map((consultation) => (
                    <option value={consultation.id} key={consultation.id}>
                      {consultation.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.consultationId && (
                  <Form.Control.Feedback>
                    {errors.consultationId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          {discountType === Discount_Type_Enum_Enum.WholeOrderWithItems && (
            <FloatingLabel label="Maksymalna liczba wystąpień" className="mb-3">
              <Form.Control
                isInvalid={!!errors.maxCount}
                type="text"
                placeholder="Cena"
                {...register('maxCount', {
                  valueAsNumber: true,
                })}
              />
              {errors.maxCount && (
                <Form.Control.Feedback>
                  {errors.maxCount.message}
                </Form.Control.Feedback>
              )}{' '}
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={upsertDiscountConsultationFetching}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default DiscountConsultationModal
