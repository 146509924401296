import { Alert, Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  Discount_Type_Enum_Enum,
  GetDiscountTestDocument,
  GetDiscountTestQuery,
  GetDiscountTestQueryVariables,
  Order_By,
  useGetDiscountTestsQuery,
  useGetTestsQuery,
  useUpsertDiscountTestMutation
} from "../generated/urql.administrator";
import { Controller, useForm } from "react-hook-form";
import { useClient } from "urql";

export type DiscountTestModalType = {
  show: (args: { discountId: number; testId?: number }) => void
}

type FormDiscountTest = {
  testId: number
  maxCount: number
}

const DiscountTestModal = forwardRef<
  DiscountTestModalType,
  {
    onChange: () => void
    discountType: Discount_Type_Enum_Enum
  }
>(({ onChange, discountType }, ref) => {
  const [show, setShow] = useState(false)
  const [discountId, setDiscountId] = useState<number>()
  const [testId, setTestId] = useState<number>()
  const client = useClient()
  const [{ fetching: upsertDiscountTestFetching }, upsertDiscountTest] =
    useUpsertDiscountTestMutation()
  const [{ data: testsData, fetching: testsFetching }] = useGetTestsQuery({
    variables: { limit: 0xfffff, orderBy: [{ shortName: Order_By.Asc }] },
  })
  const [{ data: discountTestsData }] = useGetDiscountTestsQuery({
    variables: { discountId: discountId || 0, limit: 0xfffff },
  })
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormDiscountTest>({
    defaultValues: {
      testId: undefined,
      maxCount: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ discountId, testId }) => {
      setDiscountId(discountId)
      setTestId(testId)
      reset({
        testId,
        maxCount: undefined,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (discountId && testId) {
      client
        .query<GetDiscountTestQuery, GetDiscountTestQueryVariables>(
          GetDiscountTestDocument,
          {
            discountId,
            testId,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.discount_test_by_pk) {
            reset({
              testId: data.discount_test_by_pk.testId,
              maxCount:
                typeof data.discount_test_by_pk.maxCount === 'number'
                  ? data.discount_test_by_pk.maxCount
                  : undefined,
            })
          }
        })
    }
  }, [client, discountId, reset, testId])

  async function doSaveDiscountTest(DiscountTest: FormDiscountTest) {
    setGeneralError(undefined)

    if (!discountId) {
      setGeneralError('Missing discountId')
      return
    }

    const { error } = await upsertDiscountTest({
      ...DiscountTest,
      discountId,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setDiscountId(undefined)
    setTestId(undefined)
    onChange()
  }

  const discountTests = useMemo(
    () => discountTestsData?.discount_test || [],
    [discountTestsData?.discount_test]
  )

  const availableTests = useMemo(() => {
    return (testsData?.test || []).filter(
      ({ id }) =>
        id === watch('testId') ||
        !discountTests.find((discountTest) => discountTest.testId === id)
    )
  }, [discountTests, testsData, watch])

  return (
    <Modal
      show={show}
      onHide={() => {
        setDiscountId(undefined)
        setTestId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveDiscountTest)}>
        <Modal.Header closeButton>
          <Modal.Title>Rabat - Badanie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="testId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Badanie" className="mb-3">
                <Form.Select
                  disabled={!availableTests.length || testsFetching}
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać badanie</option>
                  {availableTests.map((test) => (
                    <option value={test.id} key={test.id}>
                      {test.shortName || test.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.testId && (
                  <Form.Control.Feedback>
                    {errors.testId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          {discountType === Discount_Type_Enum_Enum.WholeOrderWithItems && (
            <FloatingLabel label="Maksymalna liczba wystąpień" className="mb-3">
              <Form.Control
                isInvalid={!!errors.maxCount}
                type="text"
                placeholder="Cena"
                {...register('maxCount', {
                  valueAsNumber: true,
                })}
              />
              {errors.maxCount && (
                <Form.Control.Feedback>
                  {errors.maxCount.message}
                </Form.Control.Feedback>
              )}{' '}
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={upsertDiscountTestFetching}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default DiscountTestModal
