import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Discount_Type_Enum_Enum,
  GetDiscountConsultationsDocument,
  GetDiscountConsultationsQuery,
  GetDiscountConsultationsQueryVariables,
  useDeleteDiscountConsultationMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PencilSquare, Plus, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import DiscountConsultationModal, {
  DiscountConsultationModalType,
} from '../../components/DiscountConsultationModal'

const BatchSize = 20

const DiscountConsultations: FC<{
  id: number
  readOnly?: boolean
  discountType: Discount_Type_Enum_Enum
}> = ({ id, readOnly, discountType }) => {
  // page content
  const pageTitle = 'Konsultacje'
  const [discountConsultations, setDiscountConsultations] = useState<
    GetDiscountConsultationsQuery['discount_consultation']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => discountConsultations, [discountConsultations])
  const client = useClient()
  const discountConsultationModalRef =
    useRef<DiscountConsultationModalType>(null)
  const [, deleteDiscountConsultation] = useDeleteDiscountConsultationMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<
          GetDiscountConsultationsQuery,
          GetDiscountConsultationsQueryVariables
        >(
          GetDiscountConsultationsDocument,
          { discountId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setDiscountConsultations(
          refetch
            ? data.discount_consultation
            : discountConsultations.concat(data.discount_consultation)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.discount_consultation_aggregate.aggregate?.count || 0)
      }
    },
    [client, discountConsultations, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            disabled={readOnly}
            onClick={() =>
              discountConsultationModalRef.current?.show({ discountId: id })
            }
          >
            <Plus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<
        GetDiscountConsultationsQuery['discount_consultation'][number]
      >
        data={data}
        columns={[
          { Header: 'ID', accessor: 'consultationId' },
          {
            Header: 'Konsultacja',
            accessor: ({ consultation }) => consultation.name,
          },
          {
            Header: 'Maksymalna liczba wystąpień',
            accessor: ({ maxCount }) => (
              <span className="text-nowrap">
                {Number.isInteger(maxCount) ? maxCount : 'nieograniczona'}
              </span>
            ),
            width:
              discountType === Discount_Type_Enum_Enum.WholeOrderWithItems
                ? 200
                : 0,
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    disabled={readOnly}
                    onClick={() =>
                      discountConsultationModalRef.current?.show({
                        discountId: id,
                        consultationId: rowData.consultationId,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    disabled={readOnly}
                    onClick={async () => {
                      if (
                        window.confirm('Czy napewno chcesz usunąć konsultację?')
                      ) {
                        await deleteDiscountConsultation({
                          discountId: id,
                          consultationId: rowData.consultationId,
                        })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <DiscountConsultationModal
        ref={discountConsultationModalRef}
        onChange={() => loadMoreRows({ refetch: true })}
        discountType={discountType}
      />
    </div>
  )
}

export default DiscountConsultations
